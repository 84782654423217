import React from 'react'
import TextLarge from '../../TypographyDescriptions/TextLarge'
import ResponsiveHeadingFourInter from '../../TypographyHeadings/ResponsiveHeadingFourInter'
import TextMedium from '../../TypographyDescriptions/TextMedium'

const IconTextDescription = ({ icon, heading, description }) => {
    return (
        <div className='flex flex-col gap-y-[12px] sm:p-[40px] p-[16px] rounded-[12px] border shadow-lg h-full'>
            { icon ? <img src={icon} className='w-[52px] aspect-square' alt="" /> : null }
            <ResponsiveHeadingFourInter color={"#372EE5"} text={heading} />
            <TextLarge isDisplay={false} text={description} fontWeight={400} color={"#424242"} className={'sm:block hidden'} />
            <TextMedium isDisplay={false} text={description} fontWeight={400} color={"#424242"} className={"sm:hidden block"} />
        </div>
    )
}

export default IconTextDescription