import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setBearerToken } from '../services/api/api';
import api from '../services/api/api'
import { updateAccessToken } from './userSlice';
import { validateEmail } from '../utils/validation';
import { onboardingEndpoint, inviteUserEndpoint, organisationEndpoint, onboardingDetailsEndpoint, onboardingFieldUpdateEndpoint, checkEmailExistenceEndpoint, bulkInviteUsersEndpoint, deleteUserEndpoint } from '../services/endPoints/onboardingAPI';
import routesConfig from "../services/config/routesConfig.json"
import { generateUrlEndPoint } from "../utils/endPointsGeneration";
import { DUpdatePaymentStatus } from './userSlice';

export const onboardingApi = createAsyncThunk(
  'onboarding/onboardingApi', async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'onboarding');
      if (!!urlGenerator) {
        const urlConfig = { data: {...state.onboarding.value, "currentStage": state.onboarding.value.currentStage===2 ? 3 : state.onboarding.value.currentStage }, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
)

export const onboardingTeamInvite = createAsyncThunk(
  'onboarding/onboardingTeamInvite', async (userDetails, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'inviteUser');
      if (!!urlGenerator) {
        const urlConfig = { data: userDetails, ...urlGenerator }
        const response = await api.request(urlConfig);
        const commingFrom = userDetails.commingFrom;
        return {response:response?.data, commingFrom};
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.response.data.errMsg);
    }
  }
)

export const organisationApi = createAsyncThunk(
  'onboarding/organisationApi', async (data, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'organisationCreation');
      if (!!urlGenerator) {
        const urlConfig = { data: { fieldName: data.fieldName, data: state.onboarding.value[data.fieldName] }, ...urlGenerator }
        const response = await api.request(urlConfig);
        if(response?.data?.organization?.subscriptionPlan){
          dispatch(DUpdatePaymentStatus({
            "planStatus": response?.data?.organization?.subscriptionPlan ?? "expired",
            "invitationStart": response?.data?.organization?.createdAt,
            "invitationExpiry": response?.data?.organization?.invitationExpiry,
            "invitedNoOfDays": response?.data?.organization?.invitedNoOfDays
          }))
        }
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
)

export const fetchOnboardingDetails = createAsyncThunk(
  "onboarding/fetchOnboardingDetails",
  async (_, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'onboardingDetails');
      if (!!urlGenerator) {
        const urlConfig = { ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const patchOnboardingDetails = createAsyncThunk(
  "onboarding/patchOnboardingDetails",
  async (data, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'setting', 'onboardingFieldUpdate');
      if (!!urlGenerator) {
        const urlConfig = { data: { fieldName: data.fieldName, "data": data.fieldName==="stageNumber" ? data.data : state.onboarding.value[data.fieldName] }, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const checkForExistanceOfEmail = createAsyncThunk(
  "onboarding/checkForExistanceOfEmail",
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'checkForEmailExistance');
      if (!!urlGenerator) {
        const urlConfig = { data: { email: data.email }, ...urlGenerator }
        const response = await api.request(urlConfig);
        const commingFrom = data.commingFrom;
        return { response: response.data, commingFrom };
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const bulkInviteUsers = createAsyncThunk(
  "onboarding/bulkInviteUsers",
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'inviteAllUsersInOnboarding');
      if (!!urlGenerator) {
        const urlConfig = { data: { users: state.onboarding.teamDetails.moreSubDetails.data }, ...urlGenerator }
        const response = await api.request(urlConfig);
        return response?.data;
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

export const onboardingdeleteuser = createAsyncThunk(
  "onboarding/onboardingdeleteuser",
  async (data, { getState, dispatch }) => {
    try {
      const urlGenerator = generateUrlEndPoint(routesConfig.root.subpaths, 'user', 'deleteUserInOnboarding');
      if (!!urlGenerator) {
        const urlConfig = { data: { email: data.email }, ...urlGenerator }
        const response = await api.request(urlConfig);
        return { response: response.data, index: data.i };
      }
    } catch (error) {
      throw new Error(error.response.data.errMsg);
    }
  }
);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    value: { currentStage: 0, Company: '', "BusinessStage": null },
    orgStatus: 'idle',
    status: 'idle',// 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    commingFromSetting: false,
    noOfUsers: 0,
    teamDetails: {
      status: 'idle',
      subDeatils: {
        email: "",
        role: "user",
        invite: false,
        buttonClicked: false
      },
      moreSubDetails: {
        data: [],
        email: "",
        role: "user",
        buttonClicked: false,
        showWarning: false
      },
      error: null,
      modelOpen: false,
    },
    fetchTeamDetailsStatus: "idle",
    showInputForMobile: true,
    loading: 'idle',
    isBulkInviteExceeded: false
  },
  reducers: {
    updateInformation: (state, action) => {
      const updatedData = { ...state.value, ...action.payload };
      state.value = updatedData
    },
    setInputValue: (state, action) => {
      state.value.Company = action.payload;
    },
    updateStepper: (state, action) => {
      state.value.currentStage = action.payload
    },
    updateBusinessDuration: (state, action) => {
      state.value['BusinessStage'] = action.payload;
    },
    updatelevel1Details: (state, action) => {
      if (action.payload.type === "email") {
        state.teamDetails.subDeatils.email = action.payload.value;
        state.teamDetails.subDeatils.invite = validateEmail(state.teamDetails.subDeatils.email)
        if (!state.teamDetails.subDeatils.invite) {
          state.teamDetails.subDeatils.buttonClicked = false
        }
      } else {
        state.teamDetails.subDeatils.role = action.payload.value;
      }
    },
    updateMoreDetails: (state, action) => {
      if (action.payload.type === "email") {
        state.teamDetails.moreSubDetails.email = action.payload.value;
      } else {
        state.teamDetails.moreSubDetails.role = action.payload.value;
      }
    },
    handleMoreButtonClicked: (state, action) => {
      if (state.teamDetails.moreSubDetails.invite) {

        state.teamDetails.moreSubDetails.buttonClicked = false;
      } else {
        state.teamDetails.moreSubDetails.buttonClicked = false;
        state.teamDetails.moreSubDetails.showWarning = true;
      }
    },
    handleButtonClicked: (state, action) => {
      if (state.teamDetails.subDeatils.invite && state.teamDetails.subDeatils.role.length > 0) {
        state.teamDetails.subDeatils.buttonClicked = true;
      } else {
        state.teamDetails.subDeatils.buttonClicked = false;
      }
    },
    updateShowWarning: (state, action) => {
      state.teamDetails.moreSubDetails.showWarning = false;
    },
    handleModelOpen: (state, action) => {
      state.teamDetails.modelOpen = action.payload.data
    },
    updatecommingFromSetting: (state, action) => {
      state.commingFromSetting = action.payload;
    },
    updateCountofUsers(state, action) {
      state.noOfUsers = state.noOfUsers + 1;
    },
    resetLevel1details(state, action) {
      state.teamDetails.subDeatils = { email: null, role: null, buttonClicked: null, invite: null }
    },
    toggleInputMobile(state, action) {
      state.showInputForMobile = !state.showInputForMobile;
    },
    deleteEmails(state, action) {
      const copyOfEmails = [...state.teamDetails.moreSubDetails.data];
      const updatedEmail = copyOfEmails.filter((_, i) => i != action.payload);
      state.teamDetails.moreSubDetails.data = updatedEmail;
    },
    resetStatusOnRefresh(state){
      state.loading = 'idle';
      state.status = 'idle';
      state.error = null;
      state.teamDetails.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(onboardingApi.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(onboardingApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(onboardingApi.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(onboardingTeamInvite.pending, (state) => {
        state.loading = "loading"
      })
      .addCase(onboardingTeamInvite.fulfilled, (state, action) => {
        state.loading = "success"
        console.log(state.noOfUsers);
        if (action.payload.commingFrom === "level1") {
          state.teamDetails.subDeatils.buttonClicked = true;
          state.teamDetails.status = 'succeeded'
        } else {
          state.teamDetails.moreSubDetails.data = [...state.teamDetails.moreSubDetails.data, { email: state.teamDetails.moreSubDetails.email, role: "user" }];
          state.teamDetails.moreSubDetails.email = '';
          state.teamDetails.moreSubDetails.role = "user";
        }
        state.noOfUsers = state.noOfUsers + 1;
      })
      .addCase(onboardingTeamInvite.rejected, (state, action) => {
        state.loading = "failed"
        state.error = action.error.message;
      })
      .addCase(fetchOnboardingDetails.pending, (state, action) => {
        state.loading = 'pending';
        state.fetchTeamDetailsStatus = "pending";
      })
      .addCase(fetchOnboardingDetails.fulfilled, (state, action) => {
        state.fetchTeamDetailsStatus = "success";
        state.loading = 'success';
        const stage = state.commingFromSetting ? {  } : { currentStage: action.payload.data?.stageNumber }
        if(!!action.payload.data){
          const value = { ...stage, Company: action.payload.data.CompanyName, "BusinessStage": action.payload.data?.BusinessStage };
          state.value = {...state.value, ...value};
        }
        const formattedData = action.payload?.users?.map((user) => {
          return { email: user.email, role: user.role.role, avatar: user.avatar }
        });
        if(formattedData.length!==0 && window.location.pathname.includes('onboarding')){
          if(window.innerWidth <= 768){
            state.teamDetails.moreSubDetails = { ...state.teamDetails.moreSubDetails, data:formattedData, buttonClicked: formattedData.length==5  }
            return;
          }
          state.teamDetails.subDeatils = {...state.teamDetails.subDeatils, email: formattedData[0].email,invite: true,buttonClicked: true}
          state.teamDetails.moreSubDetails = {...state.teamDetails.moreSubDetails, data: formattedData.slice(1),buttonClicked: formattedData.length==5}
        }else{
          state.teamDetails.moreSubDetails.data = formattedData
        }
        state.noOfUsers = formattedData?.length;
      })
      .addCase(fetchOnboardingDetails.rejected, (state, action) => {
        state.loading = 'failed'
        state.fetchTeamDetailsStatus = "failed";
      })
      .addCase(organisationApi.pending, (state) => {
        state.orgStatus = 'loading';
      })
      .addCase(organisationApi.fulfilled, (state, action) => {
        state.orgStatus = 'succeeded';
      })
      .addCase(organisationApi.rejected, (state, action) => {
        state.orgStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(checkForExistanceOfEmail.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(checkForExistanceOfEmail.fulfilled, (state, action) => {
        console.log("exceedede email");
        state.loading = 'succeeded';
        state.isBulkInviteExceeded = false;
        state.teamDetails.moreSubDetails.data = [...state.teamDetails.moreSubDetails.data, { email: state.teamDetails.moreSubDetails.email, role: "user" }];
        state.teamDetails.moreSubDetails.email = '';
        state.teamDetails.moreSubDetails.role = "user";
        state.noOfUsers = state.noOfUsers + 1;
      })
      .addCase(checkForExistanceOfEmail.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(bulkInviteUsers.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(bulkInviteUsers.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.isBulkInviteExceeded = true
      })
      .addCase(bulkInviteUsers.rejected, (state, action) => {
        state.orgStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(onboardingdeleteuser.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(onboardingdeleteuser.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.isBulkInviteExceeded = false;
        console.log(action.payload, "resp");
        if (action.payload.response.success) {
          state.noOfUsers = state.noOfUsers - 1;
          const indexToDelete = action.payload.index;
          state.teamDetails.moreSubDetails.data = state.teamDetails.moreSubDetails.data.filter(
            (user, i) => {
              console.log(i,indexToDelete);
              if(i !== indexToDelete){
                return user;
              }
            }
          );
          state.isBulkInviteExceeded = false;
          console.log(state.teamDetails.moreSubDetails.data, "updatedEmail");
        }
      })
      .addCase(onboardingdeleteuser.rejected, (state, action) => {
        state.orgStatus = 'failed';
        state.error = action.error.message;
      })
  }
})

export const onboardingApiRespData = (state) => state.onboarding.value;
export const onboardingStatus = (state) => state.onboarding.status;

export const { resetStatusOnRefresh, deleteEmails, toggleInputMobile, resetLevel1details, updateCountofUsers, updatecommingFromSetting, updateInformation, setInputValue, updateStepper, updateMoreDetails, updateBusinessDuration, updatelevel1Details, handleButtonClicked, handleMoreButtonClicked, updateShowWarning, handleModelOpen } = onboardingSlice.actions
export default onboardingSlice.reducer