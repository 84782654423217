import otest1 from "./assets/Onboarding/otest1.svg"
import otest2 from "./assets/Onboarding/otest2.svg"
import otest3 from "./assets/Onboarding/otest3.svg"
import otest4 from "./assets/Onboarding/otest4.svg"
import otest5 from "./assets/Onboarding/teamGroup.svg"

import businesscanvas from './assets/BusinessCanvas/BUSINESSCANVAS.svg'
import businessmodel from './assets/BusinessCanvas/BUSINESSMODEL.svg'
import dataroom from './assets/BusinessCanvas/DATAROOM.svg'
import pitchdeck from './assets/BusinessCanvas/PITCHDECK.svg'

import ValidateBusinessIdea from './assets/BusinessCanvas/ValidateBusinessIdea.svg'
import BuildBusinessCanvas from './assets/BusinessCanvas/BuildBusinessCanvas.svg'
import GoToMarket from './assets/BusinessCanvas/GoToMarket.svg'
import MarketAnalysis from './assets/BusinessCanvas/MarketAnalysis.svg'

import PSM from './assets/BusinessCanvas/PSM.svg'
import IdeationBingo from './assets/BusinessCanvas/IdeationBingo.svg'

import greenD from "./components/problemSolutionMatrix/docs/green.svg"
import lightBlueD from "./components/problemSolutionMatrix/docs/lightBlue.svg"
import neonD from "./components/problemSolutionMatrix/docs/neon.svg"
import voiletD from "./components/problemSolutionMatrix/docs/violet.svg"
import yellowD from "./components/problemSolutionMatrix/docs/yellow.svg"
import purpleD from "./assets/PSM/docs/purpledoc.svg";

import visa from "./assets/common/visa.svg"
import master from "./assets/common/master.svg"

import blue from "./assets/Onboarding/blue.svg";
import yellow from "./assets/Onboarding/yellow.svg";
import green from "./assets/Onboarding/green.svg";

import SendTelegram from "./assets/common/SendTelegram.svg"
import globalnternet from "./assets/common/globalnternet.svg"

import dashboardLocals from "./localization/localization_en_dashboard.json";
import tmaLocals from "./localization/localization_en_target_market_analysis.json"

export const colors = [purpleD, lightBlueD, neonD, voiletD, yellowD, greenD];

export const paymentCards = {
    visa,
    master
}

export const avatar = [blue, green, yellow];

export const MAX_USERS_IN_TEAM = 5

export const stages = [
    {
        userinput: [
            {
                type: "input",
                value: "CompanyName"
            }
        ],
        img: otest1
    },
    {
        userinput: [
            { type: 'button' },
        ],
        img: otest2
    },
    {
        userinput: [
            { type: 'customizedInput', maxAuth: 3, inputType: { inputtype: "input", dropdownoptions: [{ label: "Permission", value: "" }, { label: "user", value: "user" }, { label: "admin", value: "admin" }] } },
        ],
        img: otest5
    }
];

const bp = dashboardLocals.dashboard.dashboard_info[0].dashboard_card_description.card1.cardTitle;
const bm = dashboardLocals.dashboard.dashboard_info[0].dashboard_card_description.card2.cardTitle;
const pd = dashboardLocals.dashboard.dashboard_info[0].dashboard_card_description.card3.cardTitle;
const dr = dashboardLocals.dashboard.dashboard_info[0].dashboard_card_description.card4.cardTitle;

export const MODULE_LIST = [bp, bm, pd, dr]

export const resource_guide_fetch_data_params = {
    "problem-solution-matrix": "Problem Solution Matrix",
    "business-ideation-bingo": "Business Ideation Bingo",
    "target-market-analysis": "Target Market Analysis",
    "competitor-analysis": "Competitor Analysis",
    "ideal-customer-profile": "Ideal Customer Profile",
    "build-business-canvas": "Business Model Canvas",
    "lean-startup-canvas": "Lean Startup Canvas",
    "gtm-plan": "GTM Plan",
    "marketing-funnel": "Marketing Funnel"
}


export const exploreCoreFeature = [
    {
        "key": "0",
        "module_name": "Home",
        "module_description": dashboardLocals.dashboard.dashboard_info[0].dashboard_module_description,
        "cards": [
            { "MODULE_NAME": bp,"video_sub_path": "Business_Planning_Video_Demo", "card_Info": dashboardLocals.dashboard.dashboard_info[0].dashboard_card_description.card1, "cardImg": businesscanvas, "type": "card", itemkey: '1', bgColor: "#E5F4FF", buttonColor: "#170FB1", borderColor: "#BED8FF", strokeColor: "#B5D7F0", "learn_concept_url": null },
            { "MODULE_NAME": bm, "card_Info": dashboardLocals.dashboard.dashboard_info[0].dashboard_card_description.card2, "cardImg": businessmodel, "type": "card", itemkey: '2', bgColor: "#D8F2E7", buttonColor: "#00A55D", borderColor: "#ABE2CA", strokeColor: "#B9EAD5", "learn_concept_url": null },
            { "MODULE_NAME": pd, "card_Info": dashboardLocals.dashboard.dashboard_info[0].dashboard_card_description.card3, "cardImg": pitchdeck, "type": "card", itemkey: '3', bgColor: "#FAE2E8", buttonColor: "#7335A7", borderColor: "#E0B9C3", strokeColor: "#E0B9C3", "learn_concept_url": null },
            { "MODULE_NAME": dr, "card_Info": dashboardLocals.dashboard.dashboard_info[0].dashboard_card_description.card4, "cardImg": dataroom, "type": "card", itemkey: '4', bgColor: "#FCF4D3", buttonColor: "#CFA600", borderColor: "#EDDFA5", strokeColor: "#F5DC76", "learn_concept_url": null }
        ],
        "type": "card",
        index1: -1,
        index2: -1
    },
    {
        "key": "1",
        "module_name": "Business Planning",
        "module_description": dashboardLocals.dashboard.dashboard_info[1].dashboard_module_description,
        cards: [
            { "MODULE_NAME": bp, "video_sub_path": "Validate_Business_Idea_Video_Demo", "card_Info": dashboardLocals.dashboard.dashboard_info[1].dashboard_card_description.card1, "cardImg": ValidateBusinessIdea, "type": "card", itemkey: '1.1', bgColor: "#E5F4FF", buttonColor: "#A32C49", borderColor: "#BED8FF", "learn_concept_url": null },
            { "MODULE_NAME": bp, "video_sub_path": "Build_Market_Analysis_Video_Demo", "card_Info": dashboardLocals.dashboard.dashboard_info[1].dashboard_card_description.card2, "cardImg": MarketAnalysis, "type": "card", itemkey: '1.2', bgColor: "#E5F4FF", buttonColor: "#118FCE", borderColor: "#BED8FF", "learn_concept_url": null },
            { "MODULE_NAME": bp, "video_sub_path": "Business_Canvas_Demo_Video", "card_Info": dashboardLocals.dashboard.dashboard_info[1].dashboard_card_description.card3, "cardImg": BuildBusinessCanvas, "type": "card", itemkey: '1.3', bgColor: "#E5F4FF", buttonColor: "#DA9100", borderColor: "#BED8FF", "learn_concept_url": null },
            { "MODULE_NAME": bp, "video_sub_path": "Go_To_Market_Strategy_Video_Demo", "card_Info": dashboardLocals.dashboard.dashboard_info[1].dashboard_card_description.card4, "cardImg": GoToMarket, "type": "card", itemkey: '1.4', bgColor: "#E5F4FF", buttonColor: "#2B7859", borderColor: "#BED8FF", strokeColor: "#B5D7F0", "learn_concept_url": null }
        ],
        "type": "card",
        index1: 0,
        index2: -1
    },
    {
        "key": "1.1",
        "module_name": "Validate Business Idea",
        "module_description": dashboardLocals.dashboard.dashboard_info["1.1"].dashboard_module_description,
        cards: [
            { "MODULE_NAME": bp, "video_sub_path": "Problem_Solution_Matrix_Video_Demo", "card_Info": dashboardLocals.dashboard.dashboard_info["1.1"].dashboard_card_description.card1, "cardImg": PSM, "type": "Component", link: "/product/home/problemsolutionmatrix", bgColor: "#E5F4FF", buttonColor: "#204CA0", itemkey: "1.11", borderColor: "#BED8FF", "learn_concept_url": "/product/home/resource-guide/problem-solution-matrix" },
            { "MODULE_NAME": bp, "video_sub_path": "Business_Ideation_Bingo_Demo_Video", "card_Info": dashboardLocals.dashboard.dashboard_info["1.1"].dashboard_card_description.card2, "cardImg": IdeationBingo, "type": "Component", link: "/product/home/businessideationbingo", bgColor: "#E5F4FF", buttonColor: "#940278", itemkey: "1.12", borderColor: "#BED8FF", "learn_concept_url": "/product/home/resource-guide/business-ideation-bingo" }
        ],
        "type": "card",
        index1: 0,
        index2: 0
    },
    {
        "key": "1.2",
        "module_name": "Build Market Analysis",
        "module_description": dashboardLocals.dashboard.dashboard_info["1.2"].dashboard_module_description,
        cards: [
            { "MODULE_NAME": bp, "video_sub_path": "Ideal_Customer_Profile_Video_Demo", "card_Info": dashboardLocals.dashboard.dashboard_info["1.2"].dashboard_card_description.card3, "type": "Component", link: "/product/home/buildmarketanalysis/idealcustomerprofile", bgColor: "#E5F4FF", buttonColor: "#5A43C1", itemkey: "1.23", borderColor: "#BED8FF", "learn_concept_url": "/product/home/resource-guide/ideal-customer-profile" },
            { "MODULE_NAME": bp, "video_sub_path": "Target_Market_Demo_Video", "card_Info": dashboardLocals.dashboard.dashboard_info["1.2"].dashboard_card_description.card1, "cardImg": dataroom, "type": "Component", link: "/product/home/targetmarketanalysis", bgColor: "#E5F4FF", buttonColor: "#8100A1", itemkey: "1.21", borderColor: "#BED8FF", "learn_concept_url": "/product/home/resource-guide/target-market-analysis" },
            { "MODULE_NAME": bp, "video_sub_path": "Competitor_Analysis_Video_Demo", "card_Info": dashboardLocals.dashboard.dashboard_info["1.2"].dashboard_card_description.card2, "cardImg": dataroom, "type": "Component", link: "/product/home/business-canvas/build-market-analysis/competitor-analysis", bgColor: "#E5F4FF", buttonColor: "#94B414", itemkey: "1.22", borderColor: "#BED8FF", "learn_concept_url": "/product/home/resource-guide/competitor-analysis" },
        ],
        index1: 0,
        index2: 1
    },
    {
        "key": "1.3",
        "module_name": "Build Business Canvas",
        "module_description": dashboardLocals.dashboard.dashboard_info["1.3"].dashboard_module_description,
        cards: [
            { "MODULE_NAME": bp, "video_sub_path": null, "card_Info": dashboardLocals.dashboard.dashboard_info["1.3"].dashboard_card_description.card1, "cardImg": dataroom, "type": "Component", link: "/product/home/businesscanvas/buildbusinesscanvas", bgColor: "#E5F4FF", itemkey: "1.31", buttonColor: "#64649D", "learn_concept_url": "/product/home/resource-guide/build-business-canvas" },
            { "MODULE_NAME": bp, "video_sub_path": null, "card_Info": dashboardLocals.dashboard.dashboard_info["1.3"].dashboard_card_description.card2, "cardImg": dataroom, "type": "Component", link: "/product/home/businesscanvas/leanstartupcanvas", bgColor: "#E5F4FF", itemkey: "1.32", buttonColor: "#64649D", "learn_concept_url": "/product/home/resource-guide/lean-startup-canvas" },
        ],
        index1: 0,
        index2: 2
    },
    {
        "key": "1.4",
        "module_name": "Go To Market Strategy",
        "module_description": dashboardLocals.dashboard.dashboard_info["1.4"].dashboard_module_description,
        cards: [
            { "MODULE_NAME": bp, "video_sub_path": "GTM_Plan_Demo_Video", "card_Info": dashboardLocals.dashboard.dashboard_info["1.4"].dashboard_card_description.card1, "cardImg": GoToMarket, "type": "Component", itemkey: '1.41', bgColor: "#e4f5ff", buttonColor: "#E36879", link: "/product/home/go-to-market-strategy/gtm-plan", "learn_concept_url": "/product/home/resource-guide/gtm-plan" },
            { "MODULE_NAME": bp, "video_sub_path": "Marketing_Funnel_Demo_Video", "card_Info": dashboardLocals.dashboard.dashboard_info["1.4"].dashboard_card_description.card2, "cardImg": GoToMarket, "type": "Component", itemkey: '1.42', bgColor: "#e4f5ff", buttonColor: "#E36879", link: "/product/home/go-to-market-strategy/marketing-funnel", "learn_concept_url": "/product/home/resource-guide/marketing-funnel" },
        ],
        index1: 0,
        index2: 3
    },
    // {
    //     "key": "2", text: 'dummy',
    //     cards: [{ "cardTitle": "title4", "cardImg": pitchdeck }]
    // },
    {
        "key": "1.11",
        "module_name": "Problem Solution Matrix",
        "link": "/product/home/problemsolutionmatrix",
        "type": "component"
    },
    {
        "key": "1.12",
        "module_name": "Business Ideation Bingo",
        "link": "/product/home/businessideationbingo",
        "type": "component"
    },
    {
        "key": "1.21",
        "module_name": "Target Market Analysis",
        "link": "/product/home/targetmarketanalysis",
        "type": "component"
    },
    {
        "key": "1.22",
        "module_name": "Competitor Analysis",
        "link": "/product/home/business-canvas/build-market-analysis/competitor-analysis",
        "type": "component"
    },
    {
        "key": "1.23",
        "module_name": "Ideal Customer Profile",
        "link": "/product/home/buildmarketanalysis/idealcustomerprofile",
        "type": "component"
    },
    {
        "key": "1.31",
        "module_name": "Business Model Canvas",
        "link": "/product/home/businesscanvas/buildbusinesscanvas",
        "type": "component"
    },
    {
        "key": "1.32",
        "module_name": "Lean Startup Canvas",
        "link": "/product/home/businesscanvas/leanstartupcanvas",
        "type": "component"
    },
    {
        "key": "1.41",
        "module_name": "GTM Plan",
        "link": "/product/home/go-to-market-strategy/gtm-plan",
        "type": "component"
    },
    {
        "key": "1.42",
        "module_name": "Marketing Funnel",
        "link": "/product/home/go-to-market-strategy/marketing-funnel",
        "type": "component"
    },
    {
        "key": "1.5",
        "module_name": "My Business Plan",
        "link": "/product/home/business-planning/my-dashboard",
        "type": "component"
    },
    {
        "key": "2",
        "module_name": "Business Modeling",
        "module_description": dashboardLocals.dashboard.dashboard_info[2].dashboard_module_description,
        cards: [
            { "MODULE_NAME": bm, "card_Info": dashboardLocals.dashboard.dashboard_info[2].dashboard_card_description.card1, "cardImg": ValidateBusinessIdea, "type": "Component", itemkey: '2.1', bgColor: "#D8F2E7", buttonColor: "#A32C49", borderColor: "#BED8FF", link: "/product/home/business-modal", "learn_concept_url": null },
            { "MODULE_NAME": bm, "card_Info": dashboardLocals.dashboard.dashboard_info[2].dashboard_card_description.card2, "cardImg": MarketAnalysis, "type": "Component", itemkey: '2.2', bgColor: "#D8F2E7", buttonColor: "#118FCE", borderColor: "#BED8FF", link: "/product/home/business-modal/my-report", "isProgressVisible": false, "learn_concept_url": null },
        ],
        "type": "component",
        index1: 1,
        index2: -1
    },
    {
        "key":"2.1",
        "module_name": "My Business Model",
        "link":"/product/home/business-modal",
        "type": "component"
    },
    {
        "key":"2.2",
        "module_name": "Report",
        "link":"/product/home/business-modal/my-report",
        "type": "component"
    },
    {
        "key":"2.3",
        "module_name": "My Dashboard",
        "link":"/product/home/business-modal/my-dashboard",
        "type": "component"
    },
    {
        "key":"3",
        "module_name": "Pitch Deck",
        "link":null,
        "type": "component"
    },
    {
        "key":"4",
        "module_name": "Data Room",
        "link":null,
        "type": "component"
    },
]


export const rateFeasbilityParameter = [
    {
        bgColor: "linear-gradient(180deg, rgba(176, 255, 205, 0.50) 0%, rgba(233, 255, 241, 0.00) 100%)",
        bgTitle: "#8FFFB8",
        border: "#0A9A3F"
    },
    {
        bgColor: "linear-gradient(180deg, rgba(255, 222, 92, 0.50) 0%, rgba(255, 234, 151, 0.00) 100%)",
        bgTitle: "#FFDD56",
        border: "#AF8C03"
    },
    {
        bgColor: "linear-gradient(180deg, rgba(186, 208, 255, 0.50) 0%, rgba(202, 219, 255, 0.00) 100%)",
        bgTitle: "#92B5FF",
        border: "#073FB2"
    },
    {
        bgColor: "linear-gradient(180deg, rgba(255, 89, 102, 0.50) 0%, rgba(255, 89, 102, 0.00) 100%)",
        bgTitle: "#F4939B",
        border: "#87030D"
    },
    {
        bgColor: "linear-gradient(180deg, rgba(132, 89, 255, 0.50) 0%, rgba(132, 89, 255, 0.00) 100%)",
        bgTitle: "#A589F5",
        border: "#440BE5"
    },
    {
        bgColor: "linear-gradient(180deg, rgba(255, 212, 148, 0.50) 0%, rgba(255, 180, 67, 0.00) 100%)",
        bgTitle: "#FFC978",
        border: "#9D6005"
    },
    {
        bgColor: "linear-gradient(180deg, rgba(255, 234, 47, 0.50) 0%, rgba(255, 234, 47, 0.00) 100%)",
        bgTitle: "#F8E964",
        border: "#908201"
    },
    {
        bgColor: "linear-gradient(180deg, rgba(211, 255, 247, 0.50) 0%, rgba(106, 255, 228, 0.00) 100%)",
        bgTitle: "#C0FFF4",
        border: "#04816A"
    },
]

const business_Ideation_Bingo_BG_Color_Paleete = {
    "row1": {
        bgColor: "#FFF598",
        borderColor: "#D0C454"
    },
    "row2": {
        bgColor: "#D9F4FF",
        borderColor: "#81C4DF"
    },
    "row3": {
        bgColor: "#EFDFFE",
        borderColor: "#B08BD3"
    },
    "row4": {
        bgColor: "#FFD9D9",
        borderColor: "#C79393"
    }
}

export const businessIdeationBingoQuestion = [
    {
        "q": "Can I objectively define the problem statement in simple terms ?",
        score: 2,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row1,
        "ref": "1"
    },
    {
        "q": "Is the problem statement widely recognized or commonly observed by potential customers?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row1,
        "ref": "2"
    },
    {
        "q": "Does the problem have a significant impact on the customer's life or business?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row1,
        "ref": "3"
    },
    {
        "q": "Can customers tolerate or live with the problem, or does it significantly affect their daily lives or operations?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row1,
        "ref": "4"
    },
    {
        "q": "Do I have a clear understanding of my competitors and their offerings in the market?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row2,
        "ref": "5"
    },
    {
        "q": "Do I know who my competitors are?",
        score: 2,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row2,
        "ref": "6"
    },
    {
        "q": "Am I different from my competitors?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row2,
        "ref": "7"
    },
    {
        "q": "Have I identified my USP yet?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row2,
        "ref": "8"
    },
    {
        "q": "Do I know who my Ideal customers are?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row3,
        "ref": "9"
    },
    {
        "q": "Do I have a deep understanding of my ideal customer's needs, pain points, and preferences?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row3,
        "ref": "10"
    },
    {
        "q": "Have I conducted market research or gathered feedback from potential customers to validate the need for the product?",
        score: 2,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row3,
        "ref": "11"
    },
    {
        "q": "Would my ideal customer be willing to pay for my product?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row3,
        "ref": "12"
    },
    {
        "q": "Do I currently possess all the necessary resources (financial, human, technological, etc.) to develop and launch the product?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row4,
        "ref": "13"
    },
    {
        "q": "Are there any potential barriers or challenges in acquiring or allocating the required resources?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row4,
        "ref": "14"
    },
    {
        "q": "Have I identified potential partners or suppliers who can contribute to the product's development or delivery?",
        score: 2,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row4,
        "ref": "15"
    },
    {
        "q": "Are there any legal, regulatory, or compliance considerations I need to address in relation to the product or service?",
        score: 1,
        "color": business_Ideation_Bingo_BG_Color_Paleete.row4,
        "ref": "16"
    }
]

export const contactUs = [
    {
        cardImg: SendTelegram,
        cardTitle: "Email",
        description: "support@predictgrowth.ai"
    },
    {
        cardImg: globalnternet,
        cardTitle: "Website",
        description: "Predictgrowth.ai"
    }
]

const tmaSegmentsDemoData = tmaLocals.target_market_analysis.table.example_data_heading;

export const demoTMAData = [
    {
        key: 0,
        segmentName: "Segment A",
        APV: 200,
        PF: 6,
        ACL: 8,
        Customers: 1500,
    },
    {
        key: 1,
        segmentName: "Segment B",
        APV: 100,
        PF: 6,
        ACL: 8,
        Customers: 1500,
    },
    {
        key: 2,
        segmentName: "Segment C",
        APV: 300,
        PF: 2,
        ACL: 5,
        Customers: 1000,
    },
    {
        key: 3,
        segmentName: "Segment D",
        APV: 500,
        PF: 2,
        ACL: 2,
        Customers: 5000,
    },
];

export const demoTMAGraphColorPalette = ["#a5eebe", "#90b5fe", "#fde58a", "#faabab"]
