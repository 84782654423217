import React from 'react'
import closeactive from "../assets/closeactive.svg"
import responseLogo from "../assets/responseLogo.svg"

const ClientTag = ({ data, title="AI Generated", subTitle, handleOnClick, isDisabled=false, selectedTag }) => {
    return (
        <div className='rounded-[0.75rem] overflow-hidden border-[#BECEFF] border'>
            <div className='font-InterRegular text-[16px] font-semibold tracking-wide bg-[#dbe9fe] text-primary px-[1rem] py-[1.3rem] flex items-center gap-x-[0.5rem]'>
                <img src={responseLogo} className='w-[1.5rem]' alt="" />
                {subTitle}
            </div>
            <div className='w-full bg-white px-[1rem] py-[1.5rem] flex flex-col gap-y-[22px]'>
                <div className='flex flex-wrap gap-[8px]'>
                    {
                        data.map((d, index) => {
                            const isTagSelected = selectedTag?.includes(d);
                            return (
                                <button key={index} disabled={isDisabled} onClick={() => handleOnClick(d)} className={`border ${isTagSelected ? 'text-primary border-primary' : ''} px-[16px] py-[10px] text-[0.95rem] border-p8 bg-white rounded-md flex items-center`}>
                                    {d}
                                    {isTagSelected ? <img className='ml-[8px]' src={closeactive}/> : null}
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ClientTag