import React, { useEffect } from 'react'
import { product_shapshot } from '../../assets/website'
import MainContentSection from './MainContentSection'
import IconTextDescription from '../../DesignSystem/Cards/website/IconTextDescription'
import { product_module_sub_feature_icon } from '../../assets/website'
import IconLabel from '../../DesignSystem/Cards/website/IconLabel'
import { core_feature_icon } from '../../assets/website'
import weblocals from '../../localization/localization_en_website.json'
import ResponsiveHeadingFourInter from '../../DesignSystem/TypographyHeadings/ResponsiveHeadingFourInter'
import useScrollToTopPage from '../../hooks/useScrollToTopPage'

const ModuleTemplate = ({ locals }) => {
    useScrollToTopPage();
    return (
        <div className='flex flex-col gap-y-[50px] my-[2rem]'>
            <MainContentSection
                locals={locals.main_content}
                backgroundColor='#21208F'
                textColor='#FFFFFF'
                headingColor='#FFFFFF'
                primaryButtonClass={"text-maindarkShade1 bg-white"}
                secondaryButtonClass={"border-white text-white"}
            />
            <div className='flex flex-col sm:gap-y-[30px] gap-y-[16px] mx-[16px]'>
                <div className='mx-auto'>
                    <ResponsiveHeadingFourInter
                        className="text-center"
                        text={locals.feature.title}
                    />
                </div>
                <div className='flex flex-wrap justify-center gap-[32px]'>
                    {
                        locals.feature.list_of_features.map((feature, index) => {
                            return (
                                <div className='w-[424px] items-stretch'>
                                    <IconTextDescription
                                        key={index}
                                        icon={product_module_sub_feature_icon[feature.icon_ref_name]}
                                        heading={feature.heading}
                                        description={feature.description}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='flex flex-col sm:gap-y-[30px] gap-y-[16px]'>
                <div>
                    <img src={product_shapshot[locals.snapshot_ref_name]} alt="" />
                </div>
                <div className='mx-[16px] flex flex-col sm:gap-y-[30px] gap-y-[16px]'>
                    <ResponsiveHeadingFourInter
                        className="text-center"
                        text={locals.key_features.title}
                    />
                    <div className='flex flex-wrap justify-center gap-[12px]'>
                        {
                            locals.key_features.features.map((feature, index) => {
                                return (
                                    <div key={index} className='sm:w-[340px] w-full'>
                                        <IconLabel
                                            icon={core_feature_icon[feature.feature_ref_icon]}
                                            text={feature.name}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModuleTemplate