import airplane from './plan/airplane.svg'
import paper_rocket from './plan/paper_rocket.svg'
import pg_rocket from './plan/pg_rocket.svg'
import space_shuttle from './plan/space_shuttle.svg'

import right_arrow from './shapes/right_arrow.svg'
import tick from './shapes/tick.svg'
import down_arrow from './shapes/down_arrow.svg'
import down_arrow_primary from './shapes/down_arrow_primary.svg'
import hambuger from './shapes/hambuger.svg'
import up_arrow_primary from './shapes/up_arrow_primary.svg'
import close from './shapes/close.svg'
import right_long_arrow from './shapes/right_long_arrow.svg'
import left_long_arrow from './shapes/left_long_arrow.svg'

import paytm_phonepe from './blogs/paytm_phonepe.png';
import paytm_phonepe_sm from './blogs/paytm_phonepe_sm.png';

import physics_wallah_byjus from './blogs/physics_wallah_byjus.png';
import physics_wallah_byjus_sm from './blogs/physics_wallah_byjus_sm.png';

import swiggy_zomato from './blogs/swiggy_zomato.png';
import swiggy_zomato_sm from './blogs/swiggy_zomato_sm.png';

import zepto_blinkit_dunzo from './blogs/zepto_blinkit_dunzo.png';
import zepto_blinkit_dunzo_sm from './blogs/zepto_blinkit_dunzo_sm.png';

import business_model_early_startup from './blogs/business_model_early_startup.png';
import business_model_early_startup_sm from './blogs/business_model_early_startup_sm.png';

import business_model_elements from './blogs/business_model_elements.png';
import business_model_elements_sm from './blogs/business_model_elements_sm.png';

import pitch_deck from './blogs/pitch_deck.png';
import pitch_deck_sm from './blogs/pitch_deck_sm.png';

import strategy_execution from './blogs/strategy_execution.png';
import strategy_execution_sm from './blogs/strategy_execution_sm.png';

import financial_modeling_foundation from './blogs/financial_modeling_foundation.png';
import financial_modeling_foundation_sm from './blogs/financial_modeling_foundation_sm.png';

import roadmap_financial_model_matter from './blogs/roadmap_financial_model_matter.png';
import roadmap_financial_model_matter_sm from './blogs/roadmap_financial_model_matter_sm.png';

import financial_modeling_building_growth from './blogs/financial_modeling_building_growth.png';
import financial_modeling_building_growth_sm from './blogs/financial_modeling_building_growth_sm.png';

import financial_modeling_cost_control_strategies from './blogs/financial_modeling_cost_control_strategies.png';
import financial_modeling_cost_control_strategies_sm from './blogs/financial_modeling_cost_control_strategies_sm.png';

import financial_modeling_forecasting_revenue from './blogs/financial_modeling_forecasting_revenue.png';
import financial_modeling_forecasting_revenue_sm from './blogs/financial_modeling_forecasting_revenue_sm.png';

import financial_modeling_scenarioo_planning from './blogs/financial_modeling_scenarioo_planning.png';
import financial_modeling_scenarioo_planning_sm from './blogs/financial_modeling_scenarioo_planning_sm.png';

import financial_modeling_scenario_planning_navigating_uncertainty from './blogs/financial_modeling_scenario_planning_navigating_uncertainty.png';
import financial_modeling_scenario_planning_navigating_uncertainty_sm from './blogs/financial_modeling_scenario_planning_navigating_uncertainty_sm.png';

import financial_model_different_industry from './blogs/financial_model_different_industry.png';
import financial_model_different_industry_sm from './blogs/financial_model_different_industry_sm.png';

import business_model_breakdown_duolingo from './blogs/business_model_breakdown_duolingo.png';
import business_model_breakdown_duolingo_sm from './blogs/business_model_breakdown_duolingo_sm.png';

import airbnb from './blogs/airbnb.png';
import airbnb_sm from './blogs/airbnb_sm.png';


import mongodb_startup from './sponsorship/mongodb_startup.svg'
import aws_start_up from './sponsorship/aws_start_up.png'
import razorpay_rise from './sponsorship/razorpay_rise.png'
import microsoft_startup from './sponsorship/microsoft_startup.png'
import kabam from './sponsorship/kabam.png'
import dropon from './sponsorship/dropon.png'
import intellection from './sponsorship/intellection.png'
import ixyle_ai from './sponsorship/ixyle_ai.png'
import finplay from './sponsorship/finplay.png'
import startup_movers from './sponsorship/startup_movers.png'
import lakhanifinancialservices from './sponsorship/lakhanifinancialservices.png'
import quick_reach from './sponsorship/quick_reach.png'


import instagram from './socialMedia/instagram.svg'
import linkedin from './socialMedia/linkedin.svg'
import twitter from './socialMedia/twitter.svg'

import shapshot from './predictGrowth/shapshot.png'

import build_market_analysis from './product/business_planning/build_market_analysis.png'
import target_market_analysis from './product/business_planning/target_market_analysis.png'
import ideal_customer_profile from './product/business_planning/ideal_customer_profile.png'
import ideal_customer_profile_form from './product/business_planning/ideal_customer_profile_form.png'
import competitor_analysis from './product/business_planning/competitor_analysis.png'
import problem_solution_matrix from './product/business_planning/problem_solution_matrix.png'
import ask_ai from './product/business_planning/ask_ai.png'
import build_business_canvas from './product/business_planning/build_business_canvas.png'

import pandl from './product/business_model/pandl.png'
import revenue_screen_output from './product/business_model/revenue_screen_output.png'
import revenue_screen from './product/business_model/revenue_screen.png'
import revenue_summary from './product/business_model/revenue_summary.png'
import revenue_screen_three from './product/business_model/revenue_screen_three.png'

import brand_kit from './product/pitch_deck/brand_kit.png'
import pitch_deck_edit from './product/pitch_deck/pitch_deck_edit.png'
import pitch_deck_home from './product/pitch_deck/pitch_deck_home.png'
import slideshow from './product/pitch_deck/slideshow.png'

import data_room_files from './product/data_room/data_room_files.png'
import data_room_home from './product/data_room/data_room_home.png'
import data_room_investor from './product/data_room/data_room_investor.png'
import data_room_my_investor from './product/data_room/data_room_my_investor.png'
import data_room_qa from './product/data_room/data_room_qa.png'

import build_business_canvas_icon from './subProductFeatureIcon/build_business_canvas.svg'
import build_market_analysis_icon from './subProductFeatureIcon/build_market_analysis.svg'
import rocket_growth_icon from './subProductFeatureIcon/rocket_growth.svg'
import validate_business_idea_icon from './subProductFeatureIcon/validate_business_idea.svg'
import assignment_done from './subProductFeatureIcon/assignment_done.svg'
import founder_vault from './subProductFeatureIcon/founder_vault.svg'


import business_planning_snapshot from './snapshots/business_planning_snapshot.png'
import business_model_snapshot from './snapshots/business_model_snapshot.png'
import pitch_deck_snapshot from './snapshots/pitch_deck_snapshot.png'
import data_room_snapshot from './snapshots/data_room_snapshot.png'


import bc from './coreFeatures/bc.svg'
import bib from './coreFeatures/bib.svg'
import bp_planning from './coreFeatures/bp_planning.svg'
import ca from './coreFeatures/ca.svg'
import gtm from './coreFeatures/gtm.svg'
import icp from './coreFeatures/icp.svg'
import psm from './coreFeatures/psm.svg'
import tma from './coreFeatures/tma.svg'
import invest_eng from './subProductFeatureIcon/invest_eng.svg';
import impact_presentation from './subProductFeatureIcon/impact_presentation.svg';
import data_driven_stroy from './subProductFeatureIcon/data_driven_stroy.svg';
import stage_specific_template from './subProductFeatureIcon/stage_specific_template.svg';
import technology_integration from './subProductFeatureIcon/technology_integration.svg';
import customization_options from './subProductFeatureIcon/customization_options.svg';
import secure_file_management from './subProductFeatureIcon/secure_file_management.svg';
import granular_access_control from './subProductFeatureIcon/granular_access_control.svg';
import q_a_board from './subProductFeatureIcon/q_a_board.svg';
import access_expiry_managemnt from './subProductFeatureIcon/access_expiry_managemnt.svg';
import team_collaboration from './subProductFeatureIcon/team_collaboration.svg';
import version_control from './subProductFeatureIcon/version_control.svg';
import customizable_permission from './subProductFeatureIcon/customizable_permission.svg';
import audit_trail from './subProductFeatureIcon/audit_trail.svg';
import data_room_branding from './subProductFeatureIcon/data_room_branding.svg';
import investor_directory from './subProductFeatureIcon/investor_directory.svg';
import deal_flow_management from './subProductFeatureIcon/deal_flow_management.svg';
import document_water_marking from './subProductFeatureIcon/document_water_marking.svg';
import financial_projection from './subProductFeatureIcon/financial_projection.svg';
import profitablity_evaluation from './subProductFeatureIcon/profitablity_evaluation.svg';
import scenario_planning from './subProductFeatureIcon/scenario_planning.svg';
import sensitivity_analysis from './subProductFeatureIcon/sensitivity_analysis.svg';
import customized_reporting from './subProductFeatureIcon/customized_reporting.svg';
import variance_tracking from './subProductFeatureIcon/variance_tracking.svg';
import dashboard_analytics from './subProductFeatureIcon/dashboard_analytics.svg';


import accuracy from './whyPG/accuracy.svg'
import advanced_analytics from './whyPG/advanced_analytics.svg'
import agile_navigation from './whyPG/agile_navigation.svg'
import collaboration from './whyPG/collaboration.svg'
import customization from './whyPG/customization.svg'
import effortless_pitching from './whyPG/effortless_pitching.svg'
import fundraising from './whyPG/fundraising.svg'
import personalization from './whyPG/personalization.svg'
import scalability from './whyPG/scalability.svg'
import starategic_scaling from './whyPG/starategic_scaling.svg'
import pg_vs_excel from './whyPG/pg_vs_excel.png'
import pg_vs_templates from './whyPG/pg_vs_templates.png'


import rectangle_about_us from './shapes/rectangle_about_us.png'

import jagriti_shreya from './teamMembers/jagriti_shreya.png'
import saaniya_afreen from './teamMembers/saaniya_afreen.png'
import naquib from './teamMembers/naquib.png'
import moses_j from './teamMembers/moses_j.png'
import vamshi_magam from './teamMembers/vamshi_magam.png'

import c_suite_executives from './stories/c_suite_executives.png'
import early_stage_founders from './stories/early_stage_founders.png'
import finance_team from './stories/finance_team.png'
import growth_stage_founders from './stories/growth_stage_founders.png'
import investors from './stories/investors.png'
import solopreneurs from './stories/solopreneurs.png'

import application from './helpGuide/application.svg'
import blue_puzzle from './helpGuide/blue_puzzle.svg'
import green_flag from './helpGuide/green_flag.svg'
import green_hotspot from './helpGuide/green_hotspot.svg'
import person_search from './helpGuide/person_search.svg'
import red_arrow from './helpGuide/red_arrow.svg'
import show_list_completed from './helpGuide/show_list_completed.svg'
import spline_star from './helpGuide/spline_star.svg'
import subscription from './helpGuide/subscription.svg'
import team_member from './helpGuide/team_members.svg'
import todo_completed from './helpGuide/todo_completed.svg'

import document from './helpGuide/document.svg'
import whatsapp from './logo/whatsapp.svg'

import c_bm_revenue_screen_1 from "./product/carousal/bm_revenue_screen_1.png"
import c_bm_revenue_screen_output from "./product/carousal/bm_revenue_screen_output.png"
import c_data_room_card from "./product/carousal/data_room_card.png"
import c_data_room_investor from "./product/carousal/data_room_investor.png"
import c_pitch_deck_card from "./product/carousal/pitch_deck_card.png"
import c_pitch_deck_edit from "./product/carousal/pitch_deck_edit.png"
import c_psm_cards from "./product/carousal/psm_cards.png"
import c_tma from "./product/carousal/tma.png"

export const company_icons = {
    whatsapp
}

export const help_guide_icons = {
    application,
    blue_puzzle,
    green_flag,
    green_hotspot,
    person_search,
    red_arrow,
    show_list_completed,
    spline_star,
    subscription,
    team_members: team_member,
    todo_completed,
    document
}

export const stories_banner = {
    c_suite_executives,
    early_stage_founders,
    finance_team,
    growth_stage_founders,
    investors,
    solopreneurs
}


export const team_members = {
    jagriti_shreya,
    saaniya_afreen,
    naquib,
    moses_j,
    vamshi_magam
}

export const flexible_plan_icon = { 
    airplane,  
    paper_rocket,
    pg_rocket,
    space_shuttle
}

export const shapes = {
    tick,
    right_arrow,
    down_arrow,
    down_arrow_primary,
    up_arrow_primary,
    hambuger,
    close,
    right_long_arrow,
    left_long_arrow,
    rectangle_about_us
}

export const BLOG_IMG_REFERENCE = {
    "swiggyZomato": {
        "img": swiggy_zomato,
        "placeholder_img": swiggy_zomato_sm
    },
    "byjusPhysis": {
        "img": physics_wallah_byjus,
        "placeholder_img": physics_wallah_byjus_sm
    },
    "paytmPhonePay": {
        "img": paytm_phonepe,
        "placeholder_img": paytm_phonepe_sm
    },
    "dunzoZeptoBlinkit": {
        "img": zepto_blinkit_dunzo,
        "placeholder_img": zepto_blinkit_dunzo_sm
    },
    "business_model_early_startup": {
        "img": business_model_early_startup,
        "placeholder_img": business_model_early_startup_sm
    },
    "business_model_elements": {
        "img": business_model_elements,
        "placeholder_img": business_model_elements_sm
    },
    "pitch_deck": {
        "img": pitch_deck,
        "placeholder_img": pitch_deck_sm
    },
    "strategy_execution": {
        "img": strategy_execution,
        "placeholder_img": strategy_execution_sm
    },
    "financial_modeling_foundation": {
        "img": financial_modeling_foundation,
        "placeholder_img": financial_modeling_foundation_sm
    },
    "roadmap_financial_model_matter": {
        "img": roadmap_financial_model_matter,
        "placeholder_img": roadmap_financial_model_matter_sm
    },
    "financial_modeling_building_growth": {
        "img": financial_modeling_building_growth,
        "placeholder_img": financial_modeling_building_growth_sm
    },
    "financial_modeling_cost_control_strategies": {
        "img": financial_modeling_cost_control_strategies,
        "placeholder_img": financial_modeling_cost_control_strategies_sm
    },
    "financial_modeling_forecasting_revenue": {
        "img": financial_modeling_forecasting_revenue,
        "placeholder_img": financial_modeling_forecasting_revenue_sm
    },
    "financial_modeling_scenarioo_planning": {
        "img": financial_modeling_scenarioo_planning,
        "placeholder_img": financial_modeling_scenarioo_planning_sm
    },
    "financial_modeling_scenario_planning_navigating_uncertainty": {
        "img": financial_modeling_scenario_planning_navigating_uncertainty,
        "placeholder_img": financial_modeling_scenario_planning_navigating_uncertainty_sm
    },
    "financial_model_different_industry": {
        "img": financial_model_different_industry,
        "placeholder_img": financial_model_different_industry_sm
    },
    "business_model_breakdown_duolingo": {
        "img": business_model_breakdown_duolingo,
        "placeholder_img": business_model_breakdown_duolingo_sm
    },
    "airbnb": {
        "img": airbnb,
        "placeholder_img": airbnb_sm
    }
};

export const poweredByImages = [
    razorpay_rise,
    aws_start_up,
    mongodb_startup,
    microsoft_startup
]

export const core_trusted_by = [
    intellection,
    kabam,
    ixyle_ai,
    quick_reach
    // dropon
]

export const trusted_partners = [
    finplay,
    startup_movers,
    lakhanifinancialservices
]

export const socialMediaIcons = {
    instagram,
    linkedin,
    twitter
}

export const productAsset = {
    shapshot
}

export const productImage = {
    "business_planning": [
        build_market_analysis,
        target_market_analysis,
        ideal_customer_profile,
        ideal_customer_profile_form,
        competitor_analysis,
        problem_solution_matrix,
        ask_ai,
        build_business_canvas
    ],
    "pitch_deck": [
        brand_kit,
        pitch_deck_edit,
        pitch_deck_home,
        slideshow
    ], 
    "business_modeling": [
        revenue_screen,
        revenue_screen_output,
        revenue_summary,
        revenue_screen_three,
        pandl
    ], 
    "data_room": [
        data_room_home,
        data_room_files,
        data_room_investor,
        data_room_investor,
        data_room_qa,
        data_room_my_investor
    ]
}

export const carousal_image = [
    c_psm_cards,
    c_tma,
    c_bm_revenue_screen_1,
    c_bm_revenue_screen_output,
    c_pitch_deck_card,
    c_pitch_deck_edit,
    c_data_room_card,
    c_data_room_investor
]

export const product_module_sub_feature_icon = {
    build_business_canvas_icon,
    build_market_analysis_icon,
    rocket_growth_icon,
    validate_business_idea_icon,
    assignment_done,
    founder_vault
}

export const product_shapshot = {
    business_planning_snapshot,
    business_model_snapshot,
    pitch_deck_snapshot,
    data_room_snapshot
}

export const core_feature_icon = {
    bc,
    bib,
    bp_planning,
    ca,
    gtm,
    icp,
    psm,
    tma,
    invest_eng,
    impact_presentation,
    data_driven_stroy,
    stage_specific_template,
    technology_integration,
    customization_options,
    secure_file_management,
    granular_access_control,
    q_a_board,
    access_expiry_managemnt,
    team_collaboration,
    version_control,
    customizable_permission,
    audit_trail,
    data_room_branding,
    investor_directory,
    deal_flow_management,
    document_water_marking,
    financial_projection,
    profitablity_evaluation,
    scenario_planning,
    sensitivity_analysis,
    customized_reporting,
    variance_tracking,
    dashboard_analytics
}

export const why_pg = {
    accuracy,
    advanced_analytics,
    agile_navigation,
    collaboration,
    customization,
    effortless_pitching,
    fundraising,
    personalization,
    scalability,
    starategic_scaling,
    pg_vs_excel,
    pg_vs_templates
}