import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import responseLogo from "./assets/responseLogo.svg"

const ListOfCompetitor = ({ data, title="AI Generated" }) => {
    return (
        <div className='rounded-[0.75rem] overflow-hidden border-[#BECEFF] border'>
            {
                title
                ? (
                    <div className='font-InterRegular text-[16px] font-semibold tracking-wide bg-[#dbe9fe] text-primary px-[1rem] py-[1.3rem] flex items-center gap-x-[0.5rem]'>
                        <img src={responseLogo} className='w-[1.5rem]' alt="" />
                        {title}
                    </div>
                ) : null
            }
            <div className='w-full bg-white p-[1rem] flex flex-col gap-y-[1rem]'>
                <TextareaAutosize
                    disabled={true}
                    value={data}
                    style={{ resize: 'none', outline: 'none' }}
                    className="text-[16px] scrollbar-hide w-full rounded-b-[0.75rem] bg-white leading-[1.5rem]" />
            </div>
        </div>
    )
}

export default ListOfCompetitor