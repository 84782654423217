import React from 'react'
import responseLogo from "../assets/responseLogo.svg"

const AiResponseHeader = ({ title="AI Generated", children }) => {
    return (
        <div className='rounded-[0.75rem] overflow-hidden border-[#BECEFF] border'>
            <div className='font-InterRegular text-[16px] font-semibold tracking-wide bg-[#dbe9fe] text-primary px-[1rem] py-[1.3rem] flex items-center gap-x-[0.5rem]'>
                <img src={responseLogo} className='w-[1.5rem]' alt="" />
                {title}
            </div>
            <div className='w-full bg-white p-[1rem] flex flex-col gap-y-[1rem]'>
                {children}
            </div>
        </div>
    )
}

export default AiResponseHeader