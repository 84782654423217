import React, { useState } from 'react'
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import TextInput from '../../DesignSystem/Forms/InputVarients/TextInput';
import { handleDisableInput, handleIsConditionalRendering, appendTag, handleInputChange, handleEditShow, toogleModalOpen, handleAddFilterGeography, fetchPromptAnswer, showMarketingFunnelParentComponent } from './promptSlice';
import TextButton from '../../DesignSystem/Buttons/TextButton';
import AddListOfItem from '../../DesignSystem/Forms/AddListOfItem';
import closeactive from "./assets/closeactive.svg";
import ailocals from "../../localization/localization_en_ai.json"
import OopsPopUp from '../reuseablecomponent/PopUpVarients/OopsPopUp';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';

const icon = {
    CategoryOutlinedIcon,
    BusinessOutlinedIcon,
    LanguageOutlinedIcon,
    HomeRepairServiceOutlinedIcon
}

const PromptForm = ({ promptQuestions }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [location, setLocation] = useState("");
    const productName = useSelector((state) => state.prompt.productName);
    const CompanyName = useSelector((state) => state.onboarding.value.Company);
    const promptInputs = useSelector((state) => state.prompt.promptInputs);
    const showEdit = useSelector((state) => state.prompt.showEdit);
    const loading = useSelector((state) => state.prompt.loading);
    const bardResponses = useSelector((state) => state.prompt.bardResponses);
    const { isProblemSolutionMatrixForDisabled, isInputDisabled } = useSelector((state) => state.prompt);
    const [message1, contextHolder] = message.useMessage();
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [popUpDescription, setPopUpDescription] = useState({ description1: "", description2: "", description3: "" });
    // useSelector((state) => state.prompt.);

    const handleInputEventChange = (e) => {
        dispatch(handleInputChange({ fieldName: e.target.name, data: e.target.value }))
    }

    const handleBlurEvent = (e) => {
        const name = e.target.name;
        if (typeof promptInputs[name] === "string") {
            if (promptInputs[name].trim().length > 0) {
                dispatch(handleEditShow({ fieldName: name, isShow: true }));
            }
        }
    }

    const handleOnEdit = (index, name) => {
        if (index === 2) {
            dispatch(toogleModalOpen());
        }
        dispatch(handleEditShow({ fieldName: name, isShow: false }))
        console.log(promptInputs[name])
    }

    const handleAddItem = () => {
        if (location.trim().length > 0 && promptInputs["Geography"].length < 3) {
            dispatch(handleAddFilterGeography({ type: "add", data: location }));
            setLocation("");
        } else {
            setIsPopUpOpen(true);
            setPopUpDescription((prev) => ({ ...prev, description1: ailocals.ai.warning_message.max_geography.description1, description2: ailocals.ai.warning_message.max_geography.description2 }))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let promptIds, type;
        if (productName === "competitorAnalysis") {
            promptIds = [17];
            type = "fetchAllCompetitor";
            dispatch(fetchPromptAnswer({ type, promptIds }));
        } else if (productName === "idealCustomerProfile" || productName === "buildBusinessCanvas" || productName === "leanStartupCanvas") {
            if (bardResponses.length <= 0) {
                dispatch(appendTag({ type: "fetchBasedOnTags", tags: [], responseData: [] }));
            }
            dispatch(handleIsConditionalRendering(true));
            dispatch(handleDisableInput());
        } else if (productName === "problemSolutionMatrix") {
            dispatch(fetchPromptAnswer({ type: "problem", promptIds: [43] }));
        } else if (productName === "gtm") {
            dispatch(fetchPromptAnswer({ type: "gtmChannels", promptIds: [46] }));
        } else if (productName === "marketingFunnel") {
            dispatch(showMarketingFunnelParentComponent());
            dispatch(handleDisableInput());
        } else if (productName === "tma") {
            dispatch(fetchPromptAnswer({ type: "tmaSegmentList", promptIds: [56] }))
        }
    }

    const handleBackToModule = () => {
        switch (productName) {
            case 'competitorAnalysis': {
                navigate('/product/home/business-canvas/build-market-analysis/competitor-analysis');
                break;
            }
            case 'idealCustomerProfile': {
                navigate('/product/home/buildmarketanalysis/idealcustomerprofile');
                break;
            }
            case 'buildBusinessCanvas': {
                navigate('/product/home/businesscanvas/buildbusinesscanvas');
                break;
            }
            case 'leanStartupCanvas': {
                navigate('/product/home/businesscanvas/leanstartupcanvas');
                break;
            }
            case 'problemSolutionMatrix': {
                navigate('/product/home/problemsolutionmatrix');
                break;
            }
            case 'gtm': {
                navigate('/product/home/go-to-market-strategy/gtm-plan');
                break;
            }
            case 'marketingFunnel': {
                navigate('/product/home/go-to-market-strategy/marketing-funnel');
                break;
            }
            case 'tma': {
                navigate('/product/home/targetmarketanalysis');
                break;
            }
            default: {
                console.log("couldn't catch product name");
            }
        }
    }

    // return <pre>{JSON.stringify(promptQuestions, null, 2)}</pre>
    return (
        <div>
            <form className='grid grid-cols-2 gap-x-[1rem]'>
                <OopsPopUp description1={popUpDescription.description1} description2={popUpDescription.description2} isOpen={isPopUpOpen} onClick={() => setIsPopUpOpen(false)} />
                {contextHolder}
                {promptQuestions?.form?.map((field, index) => {
                    let formInputElement, value;
                    if (field.type === "nonInput") {
                        if (productName === "competitorAnalysis") {
                            value = CompanyName;
                        }
                        formInputElement = <TextLarge text={value} fontWeight={600} color={"#372EE5"} />
                    } else if (field.type === "input") {
                        if (!showEdit[field.name]) {
                            // console.log(promptInputs[field.name], "test");
                            formInputElement = <TextInput isRequired={field.isRequired} size='large' borderColor={"#d1d5db"} value={promptInputs[field.name]} onBlurEvent={handleBlurEvent} onChangeEvent={handleInputEventChange} name={field.name} placeholder={field.placeholder} />
                        } else {
                            formInputElement = <div className='flex items-center'>
                                <div className='w-full'>
                                    <TextLarge fontWeight={500} color={"#372EE5"} text={promptInputs[field.name]} />
                                </div>
                                <button
                                    text={"Edit"}
                                    onClick={() => handleOnEdit(index, field.name)}
                                    isDisabled={isProblemSolutionMatrixForDisabled || loading === "loading" || isInputDisabled}
                                >
                                    <EditOutlinedIcon className='text-primary' />
                                </button>
                            </div>
                        }
                    } else if (field.type === "listInput") {
                        formInputElement = <AddListOfItem isReadonly={promptInputs["Geography"].length >= 3} isDisabled={loading === "loading" || isInputDisabled} isRequired={field.isRequired && promptInputs["Geography"]?.length === 0} name={field.name} placeholder={field.placeholder} handleAddItem={handleAddItem} value={location} handleInputEventChange={(e) => setLocation(e.target.value)} />
                    }

                    let ICON = icon[field.icon];
                    if(field.name === "Industry"){
                        ICON = icon.HomeRepairServiceOutlinedIcon
                    }else if(field.name === "Geography"){
                        ICON = icon.LanguageOutlinedIcon
                    }else if(field.name === "CompanyName"){
                        ICON = icon.BusinessOutlinedIcon
                    }else if(field.name === "Product"){
                        ICON = icon.CategoryOutlinedIcon
                    }

                    return (
                        <div key={index} style={{ marginBottom: '16px' }} className='bg-white border border-[#BECEFF] rounded-[8px] w-[100%]'>
                            <div className='flex flex-col gap-x-3 gap-y-[1rem] items-center rounded-md overflow-hidden'>
                                <label className='min-h-[5rem] bg-[#dbe9fe] w-full flex gap-x-2 items-center justify-center'>
                                    {
                                        ICON
                                        ? <ICON className="text-primary" />
                                        : null
                                    }
                                    <TextLarge fontWeight={600} text={field.label} className={'text-primary'} />
                                </label>
                                <div className='flex-grow w-full text-center px-2 pb-2'>
                                    {formInputElement}
                                </div>
                            </div>
                            {field.name === "Geography" && promptInputs["Geography"].length > 0 && <div className='flex gap-x-3 items-center px-2'>
                                {/* <div className='invisible'><TextLarge fontWeight={600} text={field.label} /> - </div> */}
                                <div className='flex-grow'>
                                    <ul style={{ margin: 0 }} className='flex flex-wrap pt-[1rem]'>
                                        {
                                            promptInputs["Geography"].map((geo, index) => {
                                                return (
                                                    <li key={index} className='px-[16px] py-[0.3rem] mb-[1rem] flex items-center mr-[8px] border border-primary rounded-[16px]'>
                                                        <span>{geo}</span>
                                                        <button disabled={isInputDisabled} className='ml-2' onClick={() => dispatch(handleAddFilterGeography({ type: "remove", data: index }))} ><img src={closeactive} alt="" /></button>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>}
                        </div>
                    )
                })}
            </form>
            <div>
                <div className='flex justify-between'>
                    <SecondaryButton isDisabled={isProblemSolutionMatrixForDisabled || isInputDisabled} onClick={handleBackToModule} text={"Back"} />
                    <PrimaryButton 
                        htmlType="submit"
                        onClick={handleSubmit}
                        isDisabled={loading === "loading" || isProblemSolutionMatrixForDisabled || isInputDisabled} 
                        text={promptQuestions.buttonName} 
                        isLoading={loading === "loading" && bardResponses.length == 0}
                    />
                </div>
            </div>
        </div>
    )
}

export default PromptForm