import React, { useEffect, useState, useRef } from "react";
import power from "../assets/Header/Power.svg";
import { Button, Tooltip } from "antd";
import group from "../assets/Header/updatedIcons/group.svg";
import list from "../assets/Header/updatedIcons/list.svg";
import notification from "../assets/Header/updatedIcons/notification.svg";
import Logout from "../assets/Header/updatedIcons/Logout.svg";
import Setting from "../assets/Header/updatedIcons/Setting.svg";
import { persistor } from "../main";
import { RESET_STATE } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import UserActivity from "./UserActivity/UserActivity";
import { toggleNotification } from "./UserActivity/userActivitySlice";
import home1 from "../assets/Header/home1.svg";
import useOutsideClick from "../hooks/useOutsideClick";
import nav_bar_locals from "../localization/localization_en_nav_bar.json"
import { updateSettingTabNumber } from "../components/userSetting/settingSlice";
import PGLogoPrimary from "../assets/common/primary_Logo.svg";
import subscriptionCard from "../assets/Header/subscriptionCard.svg"
import classNames from "classnames";
import aboutPDF from "../assets/docs/Business_Planning_Help_Guide.pdf"
import { API_Fetch_HelpGuideData } from "../features/appSlice";
import { updateMenuItemSelection, updateItemSelection } from "../features/manageLinkSlice";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useApp from "../hooks/useApp";


const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const notificationRef = useRef(null);
  const { isPaymentActive, handleRestrictAccessModuleDuePayment } = useApp();
  const [countSlash, setCountSlash] = useState(null);
  const activites = useSelector((state) => state.useractivity.activites);
  const [showNotification, setShowNotification] = useState(false);
  const isAdmin = useSelector((state) => state.user.value.isAdmin);
  const isMenuCollapsed = useSelector((state) => state.link.collapsed)
  const openKeys = useSelector((state) => state.link.openKeys);
  useOutsideClick(notificationRef, () => {
    setShowNotification(false);
  })
  useEffect(() => {
    setCountSlash(0);
    location.split('/').forEach((ele) => {
      if (ele.trim().length > 0) {
        setCountSlash((prev) => prev + 1);
      }
    })
  }, [location]);
  const handleResetAndRedirect = async () => {
    dispatch({ type: RESET_STATE });
    await persistor.purge();
    navigate("/product");
  };
  const handleNavigateHome = () => {
    dispatch(updateMenuItemSelection({ openKeys: ["0"], selectedKeys: ["0"] }));
    dispatch(updateItemSelection({ activeItem: "0" }));
    navigate('/product/home');
  }
  const handleNavigatePreviousScreen = () => {
    if(location === '/product/home'){
      const slicedKey = openKeys.length>1 ? openKeys.slice(0, openKeys.length-1) : openKeys;
      dispatch(updateMenuItemSelection({ "openKeys": slicedKey, "selectedKeys": [slicedKey[slicedKey.length-1]] }))
      dispatch(updateItemSelection({ activeItem: slicedKey[slicedKey.length-1] }))
      return;
    }
    // if(location === '/product/home/setting/subscription'){
    //   navigate('/product/home/settings')
    //   return;
    // }
    window.history.back();
    // navigate(-1);
  }
  const isInEditSlidePageStyle = classNames({ 'hidden': !(window.location.pathname.includes('help-guide') || window.location.pathname.includes('resource-guide')) })
  return (
    <div className="fixed top-0 left-0 z-[10] bg-white right-0 h-[56px] 2xl:w-[1440px]">
      <div className={
        classNames(
          'flex items-center justify-between h-[100%] z-[100] pl-[12px]',
          { 'pl-[85px]': isMenuCollapsed && !(window.location.pathname.includes('help-guide') || window.location.pathname.includes('resource-guide')) },
          { 'pl-[300px]': !isMenuCollapsed && !(window.location.pathname.includes('help-guide') || window.location.pathname.includes('resource-guide')) }
        )
      }>
        <div className="flex gap-x-[40px] w-full">
          <button onClick={handleNavigateHome} className={isInEditSlidePageStyle}><img src={PGLogoPrimary} alt="" /></button>
          <div className="header_icons flex items-center">
            <div className="flex items-center">
              <ArrowBackIcon onClick={handleNavigatePreviousScreen} className="cursor-pointer" style={{ fontSize: "25px", marginRight: "20px" }} />
              <Tooltip placement="bottom" className="mr-[20px]" color="purple" title={nav_bar_locals.navbar.home.tooltip}>
                <Button
                  type="link"
                  onClick={handleNavigateHome}
                  icon={<img src={home1} className="w-[22px] h-[22px]" />}
                ></Button>
              </Tooltip>
              <Tooltip placement="bottom" className="mr-[20px]" color="volcano" title={nav_bar_locals.navbar.team.tooltip}>
                <Button
                  onClick={() => {
                    if(!isPaymentActive){
                      handleRestrictAccessModuleDuePayment();
                      return;
                    }
                    dispatch(updateSettingTabNumber("user"));
                    navigate('/product/home/settings');
                  }}
                  type="link"
                  disabled={!isAdmin}
                  icon={<img src={group} className="w-[22px] h-[22px]" />}
                ></Button>
              </Tooltip>
              <Tooltip placement="bottom" className="mr-[20px]" color="cyan" title={nav_bar_locals.navbar.about.tooltip}>
                <Button
                  type="link"
                  icon={<img src={list} className="w-[22px] h-[22px]" />}
                  onClick={() => {
                    if(!isPaymentActive){
                      handleRestrictAccessModuleDuePayment();
                      return;
                    }
                    // dispatch(API_Fetch_HelpGuideData())
                    navigate('/product/home/help-guide')
                  }}
                // href={aboutPDF}
                ></Button>
              </Tooltip>
              <Tooltip className="relative mr-[20px]" placement="bottom" color="green" title={nav_bar_locals.navbar.notification.tooltip}>
                <div ref={notificationRef}>
                  <Button
                    onClick={() => {
                      if(!isPaymentActive){
                        handleRestrictAccessModuleDuePayment();
                        return;
                      }
                      setShowNotification((prev) => !prev)
                    }}
                    type="link"
                    icon={<img src={notification} className="w-[22px] h-[22px]" />}
                  ></Button>
                  {
                    activites.length > 0 && (
                      <div className="absolute -top-[0.8rem] right-0 w-[20px] h-[20px] text-[10px] flex justify-center items-center font-InterRegular bg-[#C1232F] rounded-full text-white">{activites.length}</div>
                    )
                  }
                  <div className="absolute top-[4rem] left-0">
                    {showNotification && <UserActivity />}
                  </div>
                </div>
              </Tooltip>
              <Tooltip placement="bottom" className="mr-[20px]" color="magenta" title={nav_bar_locals.navbar.setting.tooltip}>
                <Button
                  type="link"
                  onClick={() => {
                    if(!isPaymentActive){
                      handleRestrictAccessModuleDuePayment();
                      return;
                    }
                    navigate("/product/home/settings")
                  }}
                  icon={<img src={Setting} className="w-[22px] h-[22px]" />}
                ></Button>
              </Tooltip>
              {
                isAdmin
                ? (
                  <Tooltip placement="bottom" className="mr-[20px]" color="magenta" title={nav_bar_locals.navbar.subscription.tooltip}>
                    <Button
                      type="link"
                      onClick={() => navigate("/product/home/setting/subscription")}
                      icon={<img src={subscriptionCard} className="w-[22px] h-[22px]" />}
                    ></Button>
                  </Tooltip>
                ) : null
              }
              <Tooltip placement="bottom" className="mr-[20px]" color="red" title={nav_bar_locals.navbar.logout.tooltip}>
          <Button
            type="link"
            onClick={handleResetAndRedirect}
            icon={<img src={Logout} className="w-[22px] h-[22px]" />}
          ></Button>
        </Tooltip>
            </div>
          </div>
        </div>
        {/* <Tooltip placement="bottom" className="mr-[20px]" color="red" title={nav_bar_locals.navbar.logout.tooltip}>
          <Button
            type="link"
            onClick={handleResetAndRedirect}
            icon={<img src={Logout} className="w-[22px] h-[22px]" />}
          ></Button>
        </Tooltip> */}
      </div>
    </div>
  );
};

export default Header;