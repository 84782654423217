import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { toggleWelcomeAi } from '../../AIService/promptSlice';
import responseLogo from "../../AIService/assets/responseLogo.svg"
import { useNavigate } from "react-router-dom";
import { updateProductFeature } from '../../AIService/promptSlice';
import { Modal, Button } from "antd"
import close from "../../../assets/common/close.svg"
import placeholderChatGPTLogo from "../../AIService/assets/placeholderChatGPTLogo.svg"
import modalLocals from "../../../localization/localization_en_modals.json"

const AIWelcome = ({ link, featureName }) => {
    const showWelcome = useSelector((state) => state.prompt.showWelcome);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let greetmsg;
    if (featureName === "problemSolutionMatrix") {
        greetmsg = modalLocals.ai_greet.description.problem_solution_matrix
    } else if (featureName === "competitorAnalysis") {
        greetmsg = modalLocals.ai_greet.description.competitor_analysis
    } else if (featureName === "idealCustomerProfile") {
        greetmsg = modalLocals.ai_greet.description.ideal_customer_profile
    } else if (featureName === "buildBusinessCanvas") {
        greetmsg = modalLocals.ai_greet.description.build_business_canvas
    }else if (featureName === "leanStartupCanvas") {
        greetmsg = modalLocals.ai_greet.description.lean_startup_canvas
    } else if (featureName === "gtm") {
        greetmsg = modalLocals.ai_greet.description.gtm
    } else if(featureName === "marketingFunnel") {
        greetmsg = modalLocals.ai_greet.description.marketingFunnel
    } else if(featureName === "tma"){
        greetmsg = modalLocals.ai_greet.description.tma
    }
    return (
        <div>
            <Modal open={showWelcome} className='aiModal' width={700} centered zIndex={100000}>
                <img src={placeholderChatGPTLogo} className='absolute top-[24px] left-[24px]' alt="" />
                <img src={placeholderChatGPTLogo} className='absolute right-[24px] bottom-[24px]' alt="" />
                <div className='flex justify-end '>
                    <Button className='cursor-pointer h-auto p-0 border-none' onClick={() => dispatch(toggleWelcomeAi())}>
                        <img src={close} alt="" />
                    </Button>
                </div>
                <div className='mb-[1rem]'>
                    {greetmsg?.split('\n')?.map((description,i) => <p style={{margin:0}} className='text-center text-black mt-[10px] font-DMSans text-[18px] font-bold'>{description}</p>)}
                </div>
                <div className='flex justify-center'>
                    <Button 
                        onClick={() => {
                            dispatch(updateProductFeature(featureName));
                            navigate(link);
                            dispatch(toggleWelcomeAi())
                        }} 
                        type='primary' 
                        className='flex h-auto items-center bg-primary text-white py-[10px] rounded-[8px]'
                    > 
                        <img src={responseLogo} className='mr-[8px] w-[24px]' alt="" /> 
                        <span>{modalLocals.ai_greet.ask_ai}</span>
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default AIWelcome

