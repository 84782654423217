import React from 'react'
import ListOfTags from './ListOfTags'
import responseLogo from "./assets/responseLogo.svg"
import TextareaAutosize from 'react-textarea-autosize';
import { useSelector } from "react-redux";
import ailocals from "../../localization/localization_en_ai.json"
import ListOfCompetitor from './ListOfCompetitor';
import AiResponseHeader from './components/AiResponseHeader';


const TagResponse = ({ index, respLength, loading, tags, data }) => {
    const productName = useSelector((state) => state.prompt.productName);
    let tagDescription;
    if(productName === "competitorAnalysis"){
        tagDescription = ailocals.ai.captions.tag_description.competitor_analysis
    }else if(productName === "idealCustomerProfile"){
        tagDescription = ailocals.ai.captions.tag_description.ideal_customer_profile
    } else if(productName === "buildBusinessCanvas"){
        tagDescription = ailocals.ai.captions.tag_description.build_business_canvas
    }else if(productName === "leanStartupCanvas"){
        tagDescription = ailocals.ai.captions.tag_description.lean_startup_canvas
    } else {
        tagDescription = ailocals.ai.captions.tag_description.default
    }
    return (
        <div key={index} className='flex flex-col gap-y-[2rem]'>
            <AiResponseHeader
                title={tagDescription}
            >
                <ListOfTags loading={loading} tags={tags} respLength={respLength} index={index} />
            </AiResponseHeader>
            { data?.length>0 && (
                <ListOfCompetitor
                    data={data}
                />
            )}
            
        </div>
    )
}

export default TagResponse