import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import RadioInput from '../../../DesignSystem/Forms/RadioInput'
import { handleSelectTag } from '../promptSlice';
import TextLarge from '../../../DesignSystem/TypographyDescriptions/TextLarge';

const ListOfTagWithRadioBtn = ({ listOfTags, psmPromptData, isDisabled }) => {
  const dispatch = useDispatch();
  const ProblemSelected = useSelector((state) => state.prompt.promptInputs.ProblemSelected);
  return (
    <div className='flex flex-col gap-y-5'>
      {/* <TextLarge fontWeight={500} text={"Choose a specific problem from the list that you would like to work on."} /> */}
      <RadioInput isDisabled={isDisabled} value={ProblemSelected} handleOnChange={(e) => {
        dispatch(handleSelectTag({ key: "ProblemSelected", value: e.target.value }));
      }} listOfOptions={listOfTags} />
    </div>
  )
}

export default ListOfTagWithRadioBtn