import classNames from 'classnames';
import React from 'react'
import TextLarge from '../../DesignSystem/TypographyDescriptions/TextLarge';
import TextMedium from '../../DesignSystem/TypographyDescriptions/TextMedium';
import { useSelector } from 'react-redux';

const BrainStromModeTemplate = ({ structuredData, data }) => {
    const IS_MENU_COLLAPSED = useSelector((state) => state.link.collapsed)    
    data.map((slice) => {
        for (const [key, value] of Object.entries(slice)) {
            structuredData[key] = value
        }
    })
    return (
        <section
            className={`grid gap-[1rem] p-[1rem] bg-[#3760fe] ${IS_MENU_COLLAPSED ? 'grid-cols-10' : 'grid-cols-12'}`}
        >
            {
                Object.entries(structuredData).map(([key, mainData], i) => (
                    <div
                        key={i}
                        className={classNames(
                            IS_MENU_COLLAPSED
                                ? {
                                    'border bg-white pt-1': true,
                                    'col-span-2': i <= 6,
                                    'col-span-5': [7, 8].includes(i),
                                    'row-span-2': [0, 2, 4].includes(i),
                                }
                                : {
                                    'border bg-white pt-1': true,
                                    'col-span-4': true,
                                }
                        )}
                    >
                        <TextLarge
                            fontWeight={700}
                            text={key}
                            className={'px-[0.3rem]'}
                        />
                        <ul
                            className='list-disc pl-[1rem] pr-[0.5rem] pt-1'
                        >
                            {
                                mainData?.map((d, index) => (
                                    <li
                                        key={index}
                                    >
                                        <TextMedium
                                            fontWeight={500}
                                            text={d}
                                        />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                ))
            }
        </section>
    )
}

export default BrainStromModeTemplate