import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, message } from 'antd'
import { useSelector, useDispatch } from "react-redux"
import { handleIsConditionalRendering, handlePushAndRemoveTags } from './promptSlice'
import { resetAllValues, fetchPromptAnswer, updateLoadingState } from './promptSlice'
import { useNavigate } from "react-router-dom";
import showIcon from "../AIService/assets/showIconPrimary.svg"
import html2canvas from "html2canvas";
import ShowExampleModal from '../reuseablecomponent/PopUpVarients/ShowExampleModal'
import ListOfCompetitor from './ListOfCompetitor'
import TagResponse from './TagResponse'
const { TextArea } = Input;
import ailocals from "../../localization/localization_en_ai.json"
import FloatingScrollIcon from './components/FloatingScrollIcon'
import Download from "./assets/Download.svg"
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton'
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton'

const PromptOutput = ({ forwardRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const tags = useSelector((state) => state.prompt.tags);
  const bardResponses = useSelector((state) => state.prompt.bardResponses);
  const competitorList = useSelector((state) => state.prompt.competitorList)
  const prompts = useSelector((state) => state.prompt.prompts);
  const [messageApi, contextHolder] = message.useMessage();
  const productName = useSelector((state) => state.prompt.productName)
  const loading = useSelector((state) => state.prompt.loading)
  const [showExample, setShowExample] = useState();
  useEffect(() => {
    if (loading === "success") {
      dispatch(updateLoadingState());
    }
    if (loading === "failed") {
      messageApi.open({
        type: 'error',
        content: ailocals.ai.warning_message.unknown_err,
      });
    }
  }, [loading])
  // const handleDownload = () => {
  //   html2canvas(document.querySelector("#chatdownload"), { scrollY: -window.scrollY })
  //     .then(function (canvas) {
  //       var img = canvas.toDataURL();
  //       window.open(img);
  //     });
  // }
  return (
    <div>
      {bardResponses.length != 0 && <div className='mx-auto'>
        {contextHolder}
        <div ref={forwardRef} id='chatdownload' className='pb-[1rem] px-[16px] flex flex-col gap-y-[2rem]'>
          {
            bardResponses.map((resp, i) => {
              return (
                <React.Fragment>
                  {/* <Button style={{ border: "none" }} className='h-auto'><img src={Download} alt="" /></Button> */}
                  {
                    ["fetchAllCompetitor", "gtmChannels"].includes(resp.type)
                    ? <ListOfCompetitor data={resp.responseData} />
                    : <TagResponse index={i} respLength={bardResponses.length - 1} loading={loading} tags={resp.tags} data={resp.responseData[0]} />
                  }
                </React.Fragment>
              )
            })
          }
          <div className='flex justify-between'>
            <SecondaryButton
              text={ailocals.ai.button_status[2]}
              onClick={() => navigate(-1)}
            />
            <PrimaryButton
              text={ailocals.ai.button_status[0]}
              isLoading={loading === "loading"}
              onClick={() => {
                if (bardResponses[bardResponses.length - 1].tags.length > 0) {
                  let promptIds = [];
                  let type;
                  prompts.map((prompt, index) => {
                    if (bardResponses[bardResponses.length - 1].tags.includes(prompt.subtitle)) {
                      promptIds.push(prompt.id);
                    }
                  })
                  if (productName === "competitorAnalysis") {
                    type = "fetchBasedOnTags";
                  }else if(productName === ""){
                    type = "fetchBasedOnTags"
                  }
                  dispatch(fetchPromptAnswer({ type, promptIds }));
                }
              }}
              isDisabled={bardResponses[bardResponses.length - 1].tags.length===0 || loading === "loading"}
            />
          </div>
        </div>
      </div>}
      <FloatingScrollIcon/>
    </div>
  )
}

export default PromptOutput