import React from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import ListOfCompetitor from './ListOfCompetitor';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import ailocals from '../../localization/localization_en_ai.json'
import { extractKeywords } from '../../utils/ExtractKeyWords';
import { fetchPromptAnswer, handleGtmTags, dynamicHandleAddRemoveTags } from './promptSlice';
import ClientTag from './components/ClientTag';
import SecondaryButton from '../../DesignSystem/Buttons/SecondaryButton';

const gtmOutputLabel = ailocals.ai.captions.gtm_text;

const GoToMarketStratergyOutput = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gtmDetail = useSelector((state) => state.prompt.gtm);
    const { list, list_of_tags, isShowRecursiveParentComponent, recursive_data, isFinalProcessLoading } = gtmDetail;
    const handleExtractKeywords = (data) => {
        dispatch(handleGtmTags({ "key": "isShowRecursiveParentComponent", "value": true }))
        dispatch(handleGtmTags({ "module": "gtm","key": "list_of_tags", "value": extractKeywords(data).map((option) => option.value) }))
    }
    const handleOnClick = (selectedtag, tag) => {
        const type = selectedtag.includes(tag) ? "REMOVE" : "ADD"
        dispatch(dynamicHandleAddRemoveTags({ type, module: "gtm", tag }))
    }
    if (!list) return null;
    return (
        <div className='px-[1rem] pb-[2rem]'>
            {
                !!list && (
                    <div className='mx-auto'>
                        <div className='mb-[2rem]'>
                            <ListOfCompetitor title={gtmOutputLabel.list_text} data={list} />
                            <div className='flex justify-between mt-[2rem]'>
                                <SecondaryButton isDisabled={list_of_tags?.length > 0} text={"Back"} onClick={() => navigate(-1)} />
                                <PrimaryButton isDisabled={list_of_tags?.length > 0} text={gtmOutputLabel.select_gtm_channel_text} onClick={() => handleExtractKeywords(list)} />
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isShowRecursiveParentComponent
                    ? (
                        recursive_data.map((aiResp, index) => {
                            const { selectedtag, output } = aiResp;
                            return (
                                <div className=''>
                                    {/* list of tags */}
                                    <div className='my-[2rem]'>
                                        <ClientTag subTitle={gtmOutputLabel.select_tag_description} isDisabled={index!==recursive_data.length-1} selectedTag={selectedtag} data={list_of_tags} handleOnClick={(tag) => handleOnClick(selectedtag, tag)} />
                                        <div className='flex justify-between mt-[2rem]'>
                                            <SecondaryButton isDisabled={output || isFinalProcessLoading} text={gtmOutputLabel.done} onClick={() => {
                                                navigate(-1);
                                            }}
                                            />
                                            <PrimaryButton
                                                isLoading={isFinalProcessLoading && recursive_data.length - 1 === index}
                                                isDisabled={selectedtag.length===0 || output} 
                                                text={gtmOutputLabel.view_buget_btn} 
                                                onClick={() => {
                                                    dispatch(fetchPromptAnswer({ "type": "gtmOverview", "promptIds": [47] }))
                                                    dispatch(handleGtmTags({ "key": "isFinalProcessLoading", "value": true }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* output details */}
                                    {
                                        !!output
                                            ? (
                                                <div className='my-[2rem]'>
                                                    <ListOfCompetitor title={gtmOutputLabel.final_output_text} data={output} />
                                                </div>
                                            ) : null
                                    }
                                </div>
                            )
                        })
                    ) : null
            }
        </div>
    )
}

export default GoToMarketStratergyOutput