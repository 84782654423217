import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import ListOfCompetitor from './ListOfCompetitor';
import PrimaryButton from '../../DesignSystem/Buttons/PrimaryButton';
import ailocals from '../../localization/localization_en_ai.json'
import { fetchPromptAnswer, updateLoadingStatus, updateMarketingFunnelTagSelection } from './promptSlice';
import ClientTag from './components/ClientTag';
import SecondaryButton from "../../DesignSystem/Buttons/SecondaryButton";

const gtmOutputLabel = ailocals.ai.captions.marketing_funnel_text;

const { list_text, list_of_questions, generate_output, final_output_text, done, back } = gtmOutputLabel || {};

const MarketingFunnelOutput = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { recursive_data, isFinalProcessLoading, isShowRecursiveParentComponent } = useSelector((state) => state.prompt.marketingFunnel)
    if(!isShowRecursiveParentComponent) return null;
    return (
        <div className='px-[16px] pb-[2rem]'>
            {
                isShowRecursiveParentComponent
                    ? (
                        recursive_data.map((aiResp, index) => {
                            const { selectedtag, output } = aiResp;
                            const promptId = list_of_questions.find((q) => q.label === selectedtag)?.value || null;
                            return (
                                <>
                                    {/* list of tags */}
                                    <div className="mt-[2rem]">
                                        <ClientTag
                                            subTitle={list_text}
                                            isDisabled={index !== recursive_data.length - 1}
                                            selectedTag={selectedtag} data={list_of_questions.map((q) => q.label)}
                                            handleOnClick={(tag) => dispatch(updateMarketingFunnelTagSelection(tag))}
                                        />
                                        <div className='flex justify-between my-[2rem]'>
                                            <SecondaryButton isDisabled={output || isFinalProcessLoading} text={back} onClick={() => {
                                                navigate('/product/home/go-to-market-strategy/marketing-funnel');
                                            }}
                                            />
                                            <PrimaryButton
                                                isLoading={isFinalProcessLoading && recursive_data.length - 1 === index}
                                                isDisabled={selectedtag===null || index < recursive_data.length - 1}
                                                text={generate_output}
                                                onClick={() => {
                                                    dispatch(fetchPromptAnswer({ "type": "marketingFunnelOverview", "promptIds": [promptId] }))
                                                    dispatch(updateLoadingStatus({ "key": "isFinalProcessLoading", "value": true, "module": "marketingFunnel" }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* output details */}
                                    {
                                        !!output
                                            ? (
                                                <div>
                                                    <ListOfCompetitor title={final_output_text} data={output} />
                                                </div>
                                            ) : null
                                    }
                                </>
                            )
                        })
                    ) : null
            }
        </div>
    )
}

export default MarketingFunnelOutput