import React, { useState, useEffect, useRef } from 'react'
import DescribeFeature from '../reuseablecomponent/DescribeFeature'
import { Button, Form, Input, Modal, message } from "antd";
import { appendTag, resetAllValues, resetLoadingState, updateLoadingState, handleInputChange, handleEditShow, toogleModalOpen, handleAddFilterGeography, toggleWelcomeAi, fetchPromptKeys, fetchPromptAnswer, updateProductFeature, handleIsConditionalRendering } from './promptSlice';
import { useSelector, useDispatch } from "react-redux";
import close from "./assets/close.svg";
import closeactive from "./assets/closeactive.svg";
import { useNavigate } from "react-router-dom";
import Download from "./assets/Download.svg"
import "./ai.css";
import TextComponent from '../reuseablecomponent/TextVarients/TextComponent';
import ButtonComponent from '../reuseablecomponent/ButtonVarients/ButtonComponent';
import showIcon from "../AIService/assets/showIconPrimary.svg"
import ShowExampleModal from '../reuseablecomponent/PopUpVarients/ShowExampleModal';
import PromptOutput from './PromptOutput';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PromptForm from './PromptForm';
import ProblemSolutionMatrixPromptOutput from "./ProblemSolutionMatrixPromptOutput";
import ailocals from "../../localization/localization_en_ai.json"
import FloatingScrollIcon from './components/FloatingScrollIcon';
import GoToMarketStratergyOutput from './GoToMarketStratergyOutput';
import MarketingFunnelOutput from './MarketingFunnelOutput';
import TMAOutput from './TMAOutput';
import ReactPDFDocument from './components/ReactPDFDocument';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DownloadPayloadPreparation } from './utils/DownloadPayloadPreparation';
import WarningFreeTrialAccessResource from '../reuseablecomponent/PopUpVarients/WarningFreeTrialAccessResource';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

const PromptInput = () => {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const prompt = useSelector((state) => state.prompt);
    const companyName = useSelector((state) => state.onboarding.value.Company)
    const { loading, productName, promptQuestions, introDescribeFeature, problemSolutionMatrix } = useSelector((state) => state.prompt);
    const [showExample, setShowExample] = useState(false);
    const permission = useSelector((state) => state.user.permission);

    const pdfRef = useRef();
    useEffect(() => {
        dispatch(resetLoadingState());
        dispatch(fetchPromptKeys());
        return () => {
            dispatch(resetAllValues());
            dispatch(handleIsConditionalRendering(false));
        }
    }, [])
    useEffect(() => {
        if (loading === "success") {
            dispatch(updateLoadingState());
            // navigate(`/home/ai/promptout/${productName}`);
            dispatch(handleIsConditionalRendering(true));
        }
        if (loading === "failed") {
            messageApi.open({
                type: 'error',
                content: ailocals.ai.warning_message.unknown_err,
            });
        }
    }, [loading])
    const handleToggleGeo = () => {
        dispatch(toogleModalOpen());
    }
    const showExampleToggle = () => {
        setShowExample((prev) => !prev)
    }
    const handleDownloadPDF = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 0;
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
            // const currentDate = moment();
            // const fileName = fileNameSuffix();
            pdf.save(`demo.pdf`);
        });
    }

    return (
        <div className='h-screen pt-[6.5rem] mx-auto relative'>
            {contextHolder}
            <ShowExampleModal isOpen={showExample} onClick={showExampleToggle} />
            <DescribeFeature text={ailocals.ai.captions.ask_ai_text} description={""} />
            <div id='pdf-content' className='bg-p9/50'>
                <div>
                    <div className='flex justify-between items-center'>
                        <Button onClick={() => setShowExample((prev) => !prev)} style={{ paddingLeft: '0' }} type='link' className='text-primary text-[12px] font-medium tracking-wide font-InterRegular flex items-center justify-content ml-3'><span>{ailocals.ai.captions.example_text}</span><img className='ml-[4px]' src={showIcon} /></Button>
                        <WarningFreeTrialAccessResource>
                            {
                                ({ handleTogglePopup, isFreeSubscription }) => {
                                    if (isFreeSubscription) {
                                        return (
                                            <Button onClick={handleTogglePopup} style={{ border: "none" }} className='h-auto bg-transparent'><DownloadOutlinedIcon/></Button>
                                        )
                                    }
                                    return (
                                        <PDFDownloadLink
                                            document={
                                                <ReactPDFDocument
                                                    moduleName={ailocals.ai.moduleName?.[productName]?.title || ""}
                                                    data={DownloadPayloadPreparation({ "promptDetail": prompt, "companyName": companyName })}
                                                />
                                            } fileName='ai'>
                                            {
                                                ({ loading }) => <Button disabled={!permission?.edit} style={{ border: "none" }} className='h-auto bg-transparent'><DownloadOutlinedIcon/></Button>
                                            }
                                        </PDFDownloadLink>
                                    )
                                }
                            }
                        </WarningFreeTrialAccessResource>

                    </div>
                    <div className='p-[16px]'>
                        <p className='text-[#111827] font-InterRegular text-[16px] font-semibold tracking-wide'>{introDescribeFeature}</p>
                        <PromptForm promptQuestions={promptQuestions} />
                    </div>
                </div>
                <div className='mt-[3rem]'>
                    {productName === "problemSolutionMatrix" ? <ProblemSolutionMatrixPromptOutput /> : null}
                    {["competitorAnalysis", "idealCustomerProfile", "buildBusinessCanvas", "leanStartupCanvas"].includes(productName) ? <PromptOutput forwardRef={pdfRef} /> : null}
                    {productName === "gtm" ? <GoToMarketStratergyOutput /> : null}
                    {productName === "marketingFunnel" ? <MarketingFunnelOutput /> : null}
                    {productName === "tma" ? <TMAOutput /> : null}
                </div>
            </div>
            <FloatingScrollIcon />
        </div>
    )
}

export default PromptInput